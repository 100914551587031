<template>
<div>
    <section class="content">
    <header class="heading-title">
      <h6>Deep Research</h6>
    </header>
    <hr class="separator" />
    <div class="heading">
      <div class="question-container">
        <div class="tabs">
          <div class="question-group">
            <h6 class="question">Question:</h6>
            <button class="location-button pe-none">
              <span class="button-title">
                <div class="skeleton skeleton-case-details-1 m-0"></div>
              </span>
            </button>
          </div>
          <button class="info-button" aria-label="More Info"><img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/13df0fe67b9b1b7af04bad100065f59b8dc60a9b215f3389ebf3178af602a89d?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983" alt="More Info" class="info-img" />
          </button>
        </div>
        <div class="question-details">
          <div class="skeleton skeleton-secondary"></div>
        </div>
      </div>
    </div>
    <hr class="separator" />
    <div class="heading">
      <div class="answer-container">
        <h6 class="answer-title">Nexa’s Answer:</h6>
        <div class="answer-content">
          <div class="skeleton skeleton-primary"></div>
          <div class="skeleton skeleton-secondary"></div>
          <div class="skeleton skeleton-tertiary"></div></div>
      </div>
    </div>
    <hr class="separator" />
    <section class="legal-references">
      <h6 class="legal-title">Nexa’s Legal References:</h6>
      <div class="legal-content">
        <div class="skeleton skeleton-quaternary"></div>
      </div>
      <div class="result">
        <header class="result-header">
          <nav class="result-tabs">
            <a href="#" class="tab">
              <div class="tab-content">
                <div class="skeleton skeleton-tab-1"></div></div>
            </a>
            <a href="#" class="tab">
              <div class="tab-content">
                <div class="skeleton skeleton-tab-2"></div>
              </div>
            </a>
            <a href="#" class="tab">
              <div class="tab-content">
                <div class="skeleton skeleton-tab-3"></div>
              </div>
            </a>
            <a href="#" class="tab">
              <div class="tab-content">
                <div class="skeleton skeleton-tab-4"></div></div>
            </a>
          </nav>
        </header>
        <div class="legal-cases">
          <div class="case-card">
            <div class="card-content">
              <div class="skeleton skeleton-case-1"></div>
              <div class="skeleton skeleton-case-details-1"></div>
            </div>
            <div class="card-content">
              <div class="skeleton skeleton-case-2"></div>
              <div class="skeleton skeleton-case-details-2"></div>
            </div><div class="card-content">
              <div class="skeleton skeleton-case-3"></div>
              <div class="skeleton skeleton-case-details-3"></div>
            </div>
            <div class="card-content">
              <div class="skeleton skeleton-case-4"></div>
              <div class="skeleton skeleton-case-details-4"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
       </section>
</div>
</template>
<script>
export default {
    components: {
        
  },
    data(){
        return{
            loading:true,
            sidebarCollapsed: false,
            lawbotVisible:false,
            isCountry:false,
            files: [[], []],
        }
    },
    methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
        this.$router.push({ name: 'ExpertMode' });
        },
        toggleCountry(){
      this.isCountry = !this.isCountry;
    },
    showAttachModal() {
      this.$bvModal.show('attachfile');
    },
    hideAttachModal() {
      this.$bvModal.hide('attachfile');
    },
    uploadFile(){

    }
    }
}
</script>

<style scoped>
.page-content{
  padding: 0;
}
.breadcrumb_header{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span{
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  overflow-y: auto; /* Allows vertical scrolling if content exceeds the height */
  max-height: 100vh; /* Ensure it doesn’t exceed the viewport height */
}
</style>

<style scoped>
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 2px 4px 0px rgba(170, 171, 175, 0.6);
  background-color: var(--Neutral-White, #fff);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  padding: 22px 24px;
}@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}

.heading-title {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 500;
} h6 {
  color: var(--Primary-Blue, #0e4485) !important;
}

.separator {
  background-color: #f2f3f3;
  height: 1px;
  margin-top: 32px;
  width: 100%;
  border: 1px solid rgba(242, 243, 243, 1);
}

@media (max-width: 991px) {
  .separator {
    max-width: 100%;
  }}

.heading {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 32px;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .heading {
    max-width: 100%;
  }}

.question-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.tabs {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 100px;justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .tabs {
    max-width: 100%;
  }
}

.question-group {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  white-space: nowrap;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .question-group {
    white-space: initial;
  }
}

.question,
.location-button {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0px;
}

.location-button {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: var(--Neutral-Black, #383a3e) !important;
  line-height: 1;
  margin: auto 0;
  padding: 2px 8px;
}@media (max-width: 991px) {
  .location-button {
    white-space: initial;
  }
}

.location-img {
  width: 18px;
  margin: auto 0;
}

.info-button {
  border-radius: 4px;
  display: flex;
  align-items: center;gap: 10px;
  justify-content: flex-start;
  width: 22px;
  margin: auto 0;
  padding: 2px;
}

.info-img {
  width: 18px;
  margin: auto 0;
}

.question-details {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font: 400 14px/24px Poppins, sans-serif;
  margin-top: 4px;}

.answer-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.answer-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font: 500 16px/28px Poppins, sans-serif;
}

.answer-content {
  display: flex;flex-direction: column;
  gap: 6px;
  margin-top: 6px;
}

.legal-references {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.legal-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font: 500 16px/28px Poppins, sans-serif;}

.legal-content {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.skeleton {
  border-radius: 12px;
  background-color: rgba(243, 243, 243, 1);
}

.skeleton-primary {
  width: 958px;height: 16px;
  max-width: 100%;
}

.skeleton-secondary {
  width: 684px;
  height: 16px;
  max-width: 100%;
}

.skeleton-tertiary {
  width: 1020px;
  height: 16px;
  max-width: 100%;
}.skeleton-quaternary {
  width: 100%;
  height: 16px;
}

.result {
  border-radius: 8px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  background-color: var(--Neutral-Interface-Grey, #fafbfc);
  display: flex;
  margin-top: 8px;
  width: 100%;
  flex-direction: column;
}.result-header {
  align-items: center;
  border-bottom: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.result-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: auto 0;
}.tab {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 210px;
  padding: 8px 16px;
  pointer-events: none;
}

.tab-content {
  width: 178px;
  flex-direction: column;
  display: flex;
  justify-content: center;
}.skeleton-tab-1,
.skeleton-tab-2,
.skeleton-tab-3,
.skeleton-tab-4 {
  width: 100%;
  height: 16px;
}

.legal-cases {
  display: flex;
  flex-direction: column;
  padding: 20px;
}.case-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0 12px;
}.skeleton-case-1,
.skeleton-case-2,
.skeleton-case-3,
.skeleton-case-4 {
  width: 244px;
  height: 16px;
  padding-right: 49px;
}

.skeleton-case-details-1,
.skeleton-case-details-2,
.skeleton-case-details-3,
.skeleton-case-details-4 {
  width: 128px;
  height: 16px;
  margin-top: 16px;}
</style>