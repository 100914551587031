var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"claims-header"},[_c('div',{staticClass:"d-flex align-items-start",on:{"click":function($event){return _vm.toggleCollapse()}}},[_vm._m(0),_c('div',{staticClass:"col"},[_c('h6',{staticClass:"question mb-0"},[_vm._v(_vm._s(_vm.q.question))])])]),(_vm.q.answer !== null)?_c('div',{staticClass:"action-buttons"},[_c('button',{staticClass:"action-btn copy-btn",attrs:{"disabled":_vm.isCopied,"tabindex":"0"},on:{"click":function($event){_vm.copyAnswer(JSON.parse(_vm.q.answer))}}},[_c('img',{staticClass:"action-icon",attrs:{"src":"https://cdn.builder.io/api/v1/image/assets/TEMP/a5fc1aca50cb553e4e813d1e66a4f62c328c94fc60417f9c7fb438b58331a1a1?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df","alt":"Copy content"}}),_c('span',[_vm._v(_vm._s(_vm.isCopied ? "Copied" : "Copy"))])]),_c('b-dropdown',{attrs:{"size":"sm","id":"dropdown-right","right":"","offset":"-9","variant":"link","toggle-class":"text-decoration-none","no-caret":""},on:{"click":_vm.toggleDropdown},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('button',{staticClass:"action-btn export-btn"},[_c('span',{staticClass:"button-label"},[_vm._v("Export")]),_c('img',{staticClass:"icon",class:{ 'rotate-180': _vm.isDropdown },attrs:{"src":"https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983","alt":"Export Icon"}})])]},proxy:true}],null,false,1766965760)},[_c('b-dropdown-item',{staticClass:"ms-1 me-1",on:{"click":function($event){_vm.exportToPDF(
              JSON.parse(_vm.q.answer),
              _vm.q.question,
              `Campaign ${_vm.q.campaign_id} - Question ${_vm.q.id}`
            )}}},[_c('img',{staticClass:"me-2",attrs:{"src":require("@/assets/icon/pdf-icon.svg")}}),_c('span',{staticClass:"dropdown-text"},[_vm._v("Export as PDF")])]),(JSON.parse(_vm.q.answer).table)?_c('b-dropdown-item',{staticClass:"ms-1 me-1",on:{"click":function($event){_vm.exportToExcel(
              JSON.parse(_vm.q.answer).table,
              `Campaign ${_vm.q.campaign_id} - Question ${_vm.q.id}`
            )}}},[_c('img',{staticClass:"me-2",attrs:{"src":require("@/assets/icon/excel-icon.svg")}}),_c('span',{staticClass:"dropdown-text"},[_vm._v("Export as Excel")])]):_vm._e()],1)],1):_vm._e()]),(_vm.q.answer !== null)?[(_vm.isCollapsed && JSON.parse(_vm.q.answer).string)?_c('div',{staticStyle:{"margin-top":"2%"}},[_c('vue-marked',[_vm._v(_vm._s(JSON.parse(_vm.q.answer).string))])],1):(_vm.isCollapsed && JSON.parse(_vm.q.answer).table)?_c('div',{staticStyle:{"margin-top":"2%"}},[_c('vue-marked',[_vm._v(_vm._s(JSON.parse(_vm.q.answer).table))])],1):_vm._e()]:_c('section',{staticClass:"skeleton-loader mt-4"},[_c('div',{staticClass:"skeleton-item skeleton-item-large"}),_c('div',{staticClass:"skeleton-item skeleton-item-full"}),_c('div',{staticClass:"skeleton-item skeleton-item-full"})])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto me-2 title-group"},[_c('h6',{staticClass:"text-nowrap mb-0 primary-title"},[_vm._v("Follow up Question:")])])
}]

export { render, staticRenderFns }